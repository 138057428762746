import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwCustomerLogo } from '@lib/components/SwCustomerLogo';
import { SwCustomerQuote } from '@lib/components/SwCustomerQuote';
import { SwEmphasizedText } from '@lib/components/SwEmphasizedText';
import { SwFeatureCard } from '@lib/components/SwFeatureCard';
import { SwStat } from '@lib/components/SwStat';
import { grey3, grey6 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { colors } from '@lib/components/bedrock/fundations';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { SwCallToActionBasic } from '@lib/components/call-to-action/SwCallToActionBasic';
import { SwCallToActionFullImage } from '@lib/components/call-to-action/SwCallToActionFullImage';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { zIndexes } from '@lib/enums/zIndexes';
import Seo from '@app/common/components/Seo';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import SwDetailsForm from '@app/get-in-touch/SwDetailsForm';
import logo from '../assets/icons/sweep-full.svg';
import SwLayout from '../components/v1/SwLayout';
import SwLink from '../components/v1/SwLink';
import { TOP_NAV_HEIGHT } from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const HeroContainer = styled('div')`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const Subtitle = styled(SwTypography)`
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Paper = styled('div')`
    z-index: 1;
    display: flex;
    background: white;
    align-items: start;
    flex-direction: column;
    max-width: 450px;
    height: fit-content;
    border: 1px solid ${colors.border};
    border-radius: 8px;
    padding: ${({ theme }) => theme.spacing(3)};
    gap: ${({ theme }) => theme.spacing(2)};
    margin-block-start: ${({ theme }) => theme.spacing(4)};
    inline-size: 50%;
    gap: 24px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        inline-size: 100%;
    }
`;

const TopNav = styled(Box)`
    height: ${TOP_NAV_HEIGHT};
    z-index: ${zIndexes.APP_BAR};
`;

const Container = styled('div')`
    aspect-ratio: 85/41;
    max-height: 574px;
    width: 100%;
    position: relative;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-height: auto;
        aspect-ratio: 3/4;
    }
`;

const LandingTemplate = ({ data, pageContext: { locale, compact } }) => {
    const landing = data.graphCmsLanding;

    const { t } = useTranslation();

    return (
        <>
            <TopNav data-component={'sw-top-nav'}>
                <Box alignItems={'center'} display={'flex'} height={'100%'}>
                    <SwContainer>
                        <Box
                            alignItems={'center'}
                            display={'flex'}
                            gap={8}
                            justifyContent={'space-between'}
                            width={'100%'}
                        >
                            <SwLink
                                component={'a'}
                                hovercolor={grey3}
                                href={WEBSITE_MENU_ITEM.home.link}
                                noHoverUnderlined={true}
                                variant={'h4'}
                            >
                                <Box className={'full-logo'} component={'img'} height={38} src={logo} width={'auto'} />
                            </SwLink>
                            <SwInternalLink to={'#book-a-demo-form'} variant={'primary'} withI18n={false}>
                                {t('top_nav.get_in_touch')}
                            </SwInternalLink>
                        </Box>
                    </SwContainer>
                </Box>
            </TopNav>
            <SwLayout background={grey6}>
                <Container>
                    <HeroContainer>
                        <GatsbyImage
                            alt={landing.header.image.alt}
                            image={landing.header.image.gatsbyImageData}
                            style={{ height: '100%', width: '100%' }}
                        />
                    </HeroContainer>
                    <SwContainer>
                        <Paper>
                            <SwFlexBox flexDirection={'column'} gap={12}>
                                <SwTypography
                                    bold={true}
                                    color={colors.text.secondary}
                                    component={'p'}
                                    uppercase={true}
                                    variant={'caption'}
                                >
                                    {landing.header.caption}
                                </SwTypography>
                                <SwTypography component={'h1'} variant={'h3'}>
                                    {landing.header.title}
                                </SwTypography>
                                <Subtitle color={colors.text.secondary} variant={'body1'}>
                                    {landing.header.subtitle}
                                </Subtitle>
                            </SwFlexBox>
                            <SwInternalLink size={'large'} to={'#book-a-demo-form'} withI18n={false}>
                                {landing.header.actionLabel}
                            </SwInternalLink>
                        </Paper>
                    </SwContainer>
                </Container>
                <WebsiteSectionsLayout paddingY={4}>
                    <SwContainer>
                        <Stack spacing={8}>
                            <SwGrid columns={{ xs: 1, sm: 2 }}>
                                <SwTypography bold={true} component={'h2'} variant={'h3'}>
                                    {landing.sectionTitle.title}
                                </SwTypography>
                                <SwEmphasizedText sx={{ inlineSize: '100%' }}>
                                    {landing.sectionTitle.subtitle}
                                </SwEmphasizedText>
                            </SwGrid>
                            <Stack spacing={4}>
                                {!compact ? (
                                    <>
                                        <SwCmsRenderer content={landing.mediaCards.at(0)} />
                                        <SwLine spacing={0} />
                                        <SwCmsRenderer content={landing.mediaCards.at(1)} />
                                        <SwLine spacing={0} />
                                        <SwCmsRenderer content={landing.firstEmphasizedText} />
                                        <SwLine spacing={0} />
                                    </>
                                ) : null}
                                <SwGrid columns={{ xs: 1, sm: 3 }}>
                                    {landing.firstStatistics.map((stat, index) => (
                                        <SwStat
                                            description={stat.description}
                                            key={index}
                                            kind={'secondary'}
                                            sourceLabel={stat.sourceText}
                                            sourceLink={stat.sourceLink}
                                            value={stat.value}
                                        />
                                    ))}
                                </SwGrid>
                                <SwLine spacing={0} />
                                <SwCallToActionFullImage
                                    actionLabel={t('schedule_call')}
                                    contentBackgroundColor={colors.sky[400]}
                                    image={data.firstCtaImage}
                                    link={'#book-a-demo-form'}
                                    title={landing.firstCtaTitle}
                                    withI18n={false}
                                />
                                <SwLine spacing={0} />
                                <SwCmsRenderer content={landing.secondEmphasizedText} />
                                <SwLine spacing={0} />
                                <SwGrid
                                    columns={{
                                        xs: 1,
                                        sm: 3
                                    }}
                                >
                                    {landing.featureCardLists.map((featureCard) => (
                                        <SwFeatureCard {...featureCard} />
                                    ))}
                                    <SwCallToActionBasic
                                        actionLabel={landing.cardCallToAction.actionLabel}
                                        link={{ hash: '#book-a-demo-form' }}
                                        subtitle={landing.cardCallToAction.subtitle}
                                        title={landing.cardCallToAction.title}
                                        withI18n={false}
                                    />
                                </SwGrid>
                                <SwLine spacing={0} />
                                {!compact ? (
                                    <>
                                        <SwCmsRenderer content={landing.thirdEmphasizedText} />
                                        <SwLine spacing={0} />
                                        <SwGrid columns={{ xs: 1, sm: 3 }}>
                                            {landing.secondStatistics.map((stat, index) => (
                                                <SwStat
                                                    description={stat.description}
                                                    key={index}
                                                    kind={'secondary'}
                                                    sourceLabel={stat.sourceText}
                                                    sourceLink={stat.sourceLink}
                                                    value={stat.value}
                                                />
                                            ))}
                                        </SwGrid>
                                        <SwLine spacing={0} />
                                        <SwCallToActionFullImage
                                            actionLabel={t('get_in_touch')}
                                            contentBackgroundColor={colors.sky[400]}
                                            image={data.secondCtaImage}
                                            link={'#book-a-demo-form'}
                                            title={landing.secondCtaTitle}
                                            withI18n={false}
                                        />
                                        <SwLine spacing={0} />
                                    </>
                                ) : null}
                                <SwEmphasizedText component={'h2'}>{t('our_customers')}</SwEmphasizedText>
                                <SwLine spacing={0} />
                                <SwCustomerQuote
                                    author={landing.customerQuote.author}
                                    text={landing.customerQuote.text}
                                />
                                <SwLine spacing={0} />
                                <SwPaper bgcolor={colors.surface.subdued}>
                                    <SwGrid
                                        columns={{
                                            xs: 2,

                                            md: 3,
                                            lg: 6
                                        }}
                                        gap={16}
                                    >
                                        {landing.customers.map((customer, index) => (
                                            <SwCustomerLogo customer={customer} key={index} />
                                        ))}
                                    </SwGrid>
                                </SwPaper>
                                <SwLine spacing={0} />
                                <Stack id={'book-a-demo-form'} spacing={8}>
                                    <SwTypography component={'h2'} maxWidth={['100%', '70%', '50%']} variant={'h3'}>
                                        {landing.formSectionTitle}
                                    </SwTypography>

                                    <SwDetailsForm
                                        form={data.form}
                                        values={{ form_name: `${landing.formName} ${locale.toUpperCase()}` }}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </SwContainer>
                </WebsiteSectionsLayout>
                <SwWebsiteFooter hideNewsletter={true} onlyImages={true} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        graphCmsLanding: { seo }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const getLandingQuery = graphql`
    query getLandingPageQuery($id: String!, $formName: String) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        form: hubspotForm(name: { eq: $formName }) {
            ...Essentials_HubspotForm
        }
        graphCmsLanding(id: { eq: $id }) {
            id
            seo {
                ...Essentials_GraphCMS_Seo
            }
            header {
                title
                subtitle
                caption
                actionLabel
                actionTarget {
                    ...Essentials_GraphCMS_Link
                }
                image {
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
            formName
            formSectionTitle
            firstStatistics {
                ...Essentials_GraphCMS_Statistic
            }
            customerQuote {
                ...Essentials_GraphCMS_CustomerQuote
            }
            customers {
                ...Essentials_GraphCMS_Company
            }
            cardCallToAction {
                actionLabel
                actionTarget {
                    ...Essentials_GraphCMS_Link
                }
                subtitle
                title
                id
            }
            featureCardLists {
                id
                description
                image {
                    alt
                    gatsbyImageData(height: 800)
                }
                subtitle
                title
                __typename
            }
            firstCtaTitle
            secondCtaTitle
            mediaCards {
                ...Essentials_GraphCMS_MediaCard
            }
            firstEmphasizedText {
                ...Essentials_GraphCMS_EmphasizedText
            }
            secondEmphasizedText {
                ...Essentials_GraphCMS_EmphasizedText
            }
            thirdEmphasizedText {
                ...Essentials_GraphCMS_EmphasizedText
            }
            secondStatistics {
                ...Essentials_GraphCMS_Statistic
            }
            sectionTitle {
                subtitle
                title
            }
        }
        firstCtaImage: graphCmsAsset(remoteId: { eq: "cldx2p9e72ku30buqtd7sysox" }) {
            id
            alt
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        secondCtaImage: graphCmsAsset(remoteId: { eq: "clqdj05a9pwyd0cuw5a0ejhlb" }) {
            id
            alt
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
    }
`;

export default LandingTemplate;
